import React from 'react';
import beautifulSvg from '../../assets/images/Beautiful-Little-SVG.svg';
import heritageInfinity from '../../assets/images/Heritage-Infinity.svg';
import OpenTableWidget from './OpenTableWidget';
import reservationStore from '../../stores/ReservationsStore';

const AboutSection: React.FC = () => {
  return (
    <section className="bg-black text-center text-white py-16 sm:py-24 md:py-32 lg:py-48 px-4 sm:px-8">
      {/* Header */}
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-paperboard mb-8 sm:mb-12">
        RESERVE YOUR TABLE IN A 
        <br className="hidden sm:inline" />
        DINING EXPERIENCE LIKE NO OTHER
      </h2>

      {/* OpenTable Reservation Button */}
      <div className="flex justify-center mb-8 sm:mb-12">
        <div
          onClick={() => reservationStore.toggleHeaderModalOpen()}
          className="inline-block"
          aria-label="Reserve your table on OpenTable"
        >
          <img
            className="w-24 h-auto sm:w-32 md:w-36 lg:w-40 xl:w-48"
            src="https://www.opentable.com/restaurant-solutions/badge/ot/DC2-2024.png"
            alt="Reserve your table on OpenTable"
            loading="lazy"
          />
        </div>
      </div>

      {/* OpenTable Widget */}
      <div className="w-full max-w-4xl mx-auto relative">
        <div className="relative w-full max-h-104 md:max-h-88 lg:max-h-52">
          <OpenTableWidget theme='wide' />
        </div>
      </div>

      {/* Decorative SVG */}
      <div className="flex justify-center mb-6 sm:mb-8">
        <img 
          src={beautifulSvg} 
          alt="Decorative SVG" 
          className="w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-40 xl:h-40"
          loading="lazy"
        />
      </div>

      {/* Description Paragraph */}
      <p className="text-lg sm:text-2xl md:text-3xl lg:text-5xl font-paperboard mx-auto leading-tight px-4 sm:px-8 mb-8 sm:mb-12">
        HOLDREN'S STEAKS & SEAFOOD HAS BEEN A CULINARY 
        <br className="hidden sm:inline" />
        LANDMARK, KNOWN FOR OUR EXCEPTIONAL STEAKS, 
        <br className="hidden sm:inline" />
        FRESH SEAFOOD, AND VINTAGE AMBIANCE. 
        <br className="hidden sm:inline" />
        WE OFFER A DINING EXPERIENCE THAT CELEBRATES OUR 
        <br className="hidden sm:inline" />
        HERITAGE AND COMMITMENT TO EXCELLENCE.
      </p>

      {/* Heritage Infinity SVG */}
      <div className="flex justify-center">
        <img 
          src={heritageInfinity} 
          alt="Heritage Infinity Symbol" 
          className="w-6 h-6 sm:w-8 sm:h-8 md:w-12 md:h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20"
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default AboutSection;
