import React from 'react';
import reservationStore from '../../../stores/ReservationsStore';
import { observer } from 'mobx-react-lite';

interface ReservationButtonProps {
  footer: boolean;
}

const ReservationButton: React.FC<ReservationButtonProps>= observer(({ footer }) => {
  const toggleModal = footer? reservationStore.toggleFooterModalOpen : reservationStore.toggleHeaderModalOpen;

  return (
  <button
    onClick={toggleModal}
    className="px-4 py-2 text-2xl font-paperboard transition duration-300 rounded text-black bg-white"
  >
    RESERVATIONS
  </button>
)});

export default ReservationButton;