import React from 'react';
import { motion } from 'framer-motion';
import OpenTableWidget from '../../Home/OpenTableWidget';
import { observer } from 'mobx-react-lite';
import reservationStore from '../../../stores/ReservationsStore';

interface ReservationDropdownProps {
  footer: boolean;
}

const dropdownVariants = {
  hidden: { scaleY: 0, opacity: 0 },
  visible: { scaleY: 1, opacity: 1 }
};

const ReservationDropdown: React.FC<ReservationDropdownProps> = observer(({ footer }) => {
  const dropdownPosition = footer ? 'bottom-full' : 'top-full mt-1';
  const additionalClasses = footer ? 'origin-bottom' : 'origin-top';
  
  
  const toggleModal = footer
  ? reservationStore.toggleFooterModalOpen
  : reservationStore.toggleHeaderModalOpen;

  const isExpanded = footer
  ? reservationStore.footerModalOpen
  : reservationStore.headerModalOpen;

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {isExpanded && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleModal}
        ></div>
      )}
      {isExpanded && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={dropdownVariants}
          className={`absolute right-0 bg-white shadow-lg bg-[#fff8eb] z-50 ${additionalClasses} ${dropdownPosition}`}
          transition={{ duration: 1.0 }}
          onClick={stopPropagation} // Stop propagation to prevent the dropdown from closing
        >
          <div className="iframe-container">
            <OpenTableWidget theme='tall' />
          </div>
        </motion.div>
      )}
    </>
  );
});

export default ReservationDropdown;