import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';
import NavLinks from './NavLinks';
import ReservationButton from './ReservationButton';
import MobileMenu, { MobileMenuButton } from './MobileMenu';
import ReservationDropdown from './ReservationDropdown';
import { observer } from 'mobx-react-lite';

const Header: React.FC = observer(() => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const location = useLocation();
  const { pathname } = location;

  return pathname !== '/login' && pathname !== '/reset-password' && pathname !== '/register' && pathname !== '/confirmation-instruction' ? (
    <>
      <header className="fixed top-0 left-0 w-full bg-black bg-opacity-70 text-white flex items-center justify-between px-6 md:px-24 py-4 md:py-12 z-50">
        <Logo />
        <div className="hidden lg:flex items-center justify-end flex-grow">
          <NavLinks />
          <div className="ml-8 relative">
            <ReservationButton footer={false}/>
          </div>
        </div>
        <ReservationDropdown footer={false}/>
        <MobileMenuButton toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      </header>
      <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </>
  ) : null;
});

export default Header;