// src/components/SantaBarbaraMenu.tsx
import React from 'react';
import Menu from './Menu';

const SantaBarbaraMenu: React.FC = () => {
  return (
    <Menu
      menuName="Santa Barbara Menu"
      specialCategories={['22 Year Anniversary Special Menu']}
    />
  );
};

export default SantaBarbaraMenu;